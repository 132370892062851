<template>
  <div>
    <div class="zoom icon-yellow">
      <v-card class="mb-4 px-6 py-4">
        <v-row
          justify="start"
          class="flex-column flex-md-row"
        >
          <v-col
            md="2"
            cols="12"
            class="d-flex align-center justify-start pr-0 pr-md-5"
          >
            <v-icon
              large
              color="#FFD700"
            >
              {{ mdiMapSearchOutline }}
            </v-icon>
            <h2 class="pl-3">
              Donte
            </h2>
          </v-col>

          <v-divider
            vertical
            class="d-none d-md-flex"
          ></v-divider>
          <v-divider
            class="d-flex d-md-flex"
          ></v-divider>

          <v-col
            md="10"
            cols="12"
            class="pl-md-9 text-justify"
          >
            Donte is a module that enables you to generate a statistics report about the traffic on a specific
            location over a period of time; based on demographics, time spent on site, number of people on site.
            Demographic statistics include information about visitors' gender, age, country of residence.
            In order to generate a Donte report one needs to take three steps.
            Number one is choose the location on the map for which we intend to generate a report.
            This is done by selecting the area on the map. Second step allows the user to choose which geohash
            cells we wish to include in the report, the ones marked with a color green will be included.
            The third step is where we choose the parameters by which the report should be generated.

            <div class="pt-3">
              As we’ve gone through the necessary steps to generate a <strong>Donte report</strong>, this is where we can find it,
              check the information and export it in a .pdf format.
            </div>
          </v-col>
        </v-row>
      </v-card>
    </div>

    <div class="zoom">
      <v-card class="mb-4 px-6 py-4">
        <v-row
          justify="start"
          class="flex-column flex-md-row"
        >
          <v-col
            md="2"
            cols="12"
            class="d-flex align-center justify-start pr-0 pr-md-5"
          >
            <v-icon
              large
              color="#991E66"
            >
              {{ mdiRunFast }}
            </v-icon>
            <h2 class="pl-3">
              Meer
            </h2>
          </v-col>

          <v-divider
            vertical
            class="d-none d-md-flex"
          ></v-divider>
          <v-divider
            class="d-flex d-md-flex"
          ></v-divider>

          <v-col
            md="10"
            cols="12"
            class="pl-md-9 text-justify"
          >
            Using the data from the telecom infrastructure and thanks to advanced algorithms, Meer as a module is
            showing us the approximate direction from which a group of users came to a certain location, the
            approximate direction in which they are moving as well as the average speed of their movement.
            The heatmap shows us where the largest number of users is, by changing the time parameter we can as
            well estimate the duration of the users’ stay in a location.
          </v-col>
        </v-row>
      </v-card>
    </div>

    <div class="zoom">
      <v-card class="mb-4 px-6 py-4">
        <v-row
          justify="start"
          class="flex-column flex-md-row"
        >
          <v-col
            md="2"
            cols="12"
            class="d-flex align-center justify-start pr-0 pr-md-5"
          >
            <v-icon
              large
              color="#B7BF10"
            >
              {{ mdiAccessPoint }}
            </v-icon>
            <h2 class="pl-3">
              Picolus
            </h2>
          </v-col>

          <v-divider
            vertical
            class="d-none d-md-flex"
          ></v-divider>
          <v-divider
            class="d-flex d-md-flex"
          ></v-divider>

          <v-col
            md="10"
            cols="12"
            class="pl-md-9 text-justify"
          >
            Picolus report, unlike Donte, as a source of information uses the data from the so called Pico cells.
            They offer somewhat more precise information on the number of users on a specific location.
            Within the 150 m radius. In order to generate a Picolus report we need to set the time frame and choose a
            site of our interest where Pico cells are located. The report is then generated together with a map.
          </v-col>
        </v-row>
      </v-card>
    </div>

    <div class="zoom">
      <v-card class="mb-4 px-6 py-4">
        <v-row
          justify="start"
          class="flex-column flex-md-row"
        >
          <v-col
            md="2"
            cols="12"
            class="d-flex align-center justify-start pr-0 pr-md-5"
          >
            <v-icon
              large
              color="#FFD700"
            >
              {{ mdiHumanGreeting }}
            </v-icon>
            <h2 class="pl-3">
              Teddy
            </h2>
          </v-col>

          <v-divider
            vertical
            class="d-none d-md-flex"
          ></v-divider>
          <v-divider
            class="d-flex d-md-flex"
          ></v-divider>

          <v-col
            md="10"
            cols="12"
            class="pl-md-9 text-justify"
          >
            Teddy is a heat map that shows us the number of network users on different locations in the city of Rijeka.
            This module enables us to choose which groups of users we wish to have represented in the map.
            There is a set of filters to choose from besides the time frame - gender, age, tariff (private or business)
            and nationality. As we’ve chosen the filters, the heat map is shown accordingly.
          </v-col>
        </v-row>
      </v-card>
    </div>

    <div class="zoom">
      <v-card class="mb-4 px-6 py-4">
        <v-row
          justify="start"
          class="flex-column flex-md-row"
        >
          <v-col
            md="2"
            cols="12"
            class="d-flex align-center justify-start pr-0 pr-md-5"
          >
            <v-icon
              large
              color="#991E66"
            >
              {{ mdiCar }}
            </v-icon>
            <h2 class="pl-3">
              Votmax
            </h2>
          </v-col>

          <v-divider
            vertical
            class="d-none d-md-flex"
          ></v-divider>
          <v-divider
            class="d-flex d-md-flex"
          ></v-divider>

          <v-col
            md="10"
            cols="12"
            class="pl-md-9 text-justify"
          >
            Votmax is a machine learning project. The software connected to a traffic camera is able
            to count the number of vehicles on the road or in marine traffic and deduct which vehicle is in question:
            a car, a van, a truck, or a motorcycle. The data is shown in a line graph and in a table.
          </v-col>
        </v-row>
      </v-card>
    </div>

    <div class="zoom">
      <v-card class="mb-4 px-6 py-4">
        <v-row
          justify="start"
          class="flex-column flex-md-row"
        >
          <v-col
            md="2"
            cols="12"
            class="d-flex align-center justify-start pr-0 pr-md-5"
          >
            <v-icon
              large
              color="#B7BF10"
            >
              {{ mdiLeaf }}
            </v-icon>
            <h2 class="pl-3">
              Zanty
            </h2>
          </v-col>

          <v-divider
            vertical
            class="d-none d-md-flex"
          ></v-divider>
          <v-divider
            class="d-flex d-md-flex"
          ></v-divider>

          <v-col
            md="10"
            cols="12"
            class="pl-md-9 text-justify"
          >
            Zanty module uses the data collected from the air sensors installed around the city of Rijeka.
            The data is shown in a form of a heatmap and in the table, it shows us the levels of air pollution
            in different locations in the city.
          </v-col>
        </v-row>
      </v-card>
    </div>
  </div>
</template>

<script>
/* eslint-disable */
import { mdiMapSearchOutline, mdiRunFast, mdiAccessPoint, mdiHumanGreeting, mdiCar, mdiLeaf } from '@mdi/js'

export default {
  data() {
    return {
      mdiMapSearchOutline,
      mdiRunFast,
      mdiAccessPoint,
      mdiHumanGreeting,
      mdiCar,
      mdiLeaf,
    }
  }
}
</script>

<style lang="scss">
.zoom {
  transition: transform 250ms ease-in-out;
  &:hover {
    transform: scale(1.033);
  }
}
</style>
